const sampleOutput = `
{
  "_embedded": {
    "shop:event": {
      "_links": {
        "curies": [
          {
            "href": "https://shopping-api-docs.paylogic.com/documentation/{rel}.html",
            "name": "shop",
            "templated": true,
            "type": "text/html"
          }
        ],
        "profile": {
          "href": "https://shopping-api-docs.paylogic.com/documentation/event.html",
          "type": "text/html"
        },
        "self": {
          "href": "https://shopping-api.paylogic.com/events/ba78ce546f7a49a7a0dfea5e101ae1f0"
        },
        "shop:event": {
          "href": "https://shopping-api.paylogic.com/events"
        },
        "shop:product": {
          "href": "https://shopping-api.paylogic.com/products?event=https%3A%2F%2Fshopping-api.paylogic.com%2Fevents%2Fba78ce546f7a49a7a0dfea5e101ae1f0"
        },
        "shop:tickets": {
          "href": "https://shopping-api.paylogic.com/tickets?event=https%3A%2F%2Fshopping-api.paylogic.com%2Fevents%2Fba78ce546f7a49a7a0dfea5e101ae1f0&code={barcode}",
          "templated": true
        }
      },
      "event_end": "[[DATENOW:2017-09-25T11:00:00Z]]",
      "event_start": "[[DATENOW:2017-09-25T23:30:00Z]]",
      "reference": "108663",
      "ticket_transfer_deadline": null,
      "time_zone": "Europe/Amsterdam",
      "title": {
        "en": "[[EVENTNAME]]",
      },
      "uid": "ba78ce546f7a49a7a0dfea5e101ae1f0",
      "use_cashless": false
    },
    "shop:product": [
      {
        "_links": {
          "curies": [
            {
              "href": "https://shopping-api-docs.paylogic.com/documentation/{rel}.html",
              "name": "shop",
              "templated": true,
              "type": "text/html"
            }
          ],
          "profile": {
            "href": "https://shopping-api-docs.paylogic.com/documentation/product.html",
            "type": "text/html"
          },
          "self": {
            "href": "https://shopping-api.paylogic.com/products/90daa1013b134a9caf4d6b42874a678f"
          },
          "shop:event": {
            "href": "https://shopping-api.paylogic.com/events/ba78ce546f7a49a7a0dfea5e101ae1f0"
          }
        },
        "combo": null,
        "max_per_order": 20,
        "min_per_order": 1,
        "name": {
          "en": "Regular Product"
        },
        "parents": null,
        "personalization_required": false,
        "price": {
          "amount": "25.00",
          "currency": "EUR"
        },
        "reference": "202357",
        "separately_saleable": true,
        "sold_out": false,
        "uid": "90daa1013b134a9caf4d6b42874a678f",
        "viewing_order": null
      }
    ],
    "shop:ticket": [
      {
        "_links": {
          "curies": [
            {
              "href": "https://shopping-api-docs.paylogic.com/documentation/{rel}.html",
              "name": "shop",
              "templated": true,
              "type": "text/html"
            }
          ],
          "profile": {
            "href": "https://shopping-api-docs.paylogic.com/documentation/ticket.html",
            "type": "text/html"
          },
          "self": {
            "href": "https://shopping-api.paylogic.com/tickets/7bce39be494a4f78be47544d994c02b9"
          },
          "shop:event": {
            "href": "https://shopping-api.paylogic.com/events/ba78ce546f7a49a7a0dfea5e101ae1f0"
          },
          "shop:order": {
            "href": "https://shopping-api.paylogic.com/orders/a39f2820687b4c2c9a436920aad150de"
          },
          "shop:product": {
            "href": "https://shopping-api.paylogic.com/products/90daa1013b134a9caf4d6b42874a678f"
          },
          "shop:ticket_transfer_approvals": {
            "href": "https://shopping-api.paylogic.com/ticket-transfer-approvals"
          }
        },
        "code": "",
        "consumer": {
          "address": "De Ruijterkade 113",
          "city": "Amsterdam",
          "country": "NL",
          "date_of_birth": "1985-03-08",
          "email": "johndoe@[[EVENTNAME]].com",
          "first_name": "John",
          "gender": 1,
          "ip": "[[IPADDRESS]]",
          "language": "en",
          "last_name": "Doe",
          "phone_number": "088-7000100",
          "postal_code": "1011 AB",
          "state": ""
        },
        "needs_personalization": false,
        "status": "finalized",
        "ticket_transfer_deadline": null,
        "uid": "7bce39be494a4f78be47544d994c02b9"
      }
    ]
  },
  "_links": {
    "curies": [
      {
        "href": "https://shopping-api-docs.paylogic.com/documentation/{rel}.html",
        "name": "shop",
        "templated": true,
        "type": "text/html"
      }
    ],
    "payment": {
      "href": "https://payments.paylogic.com?redirect=https%3A//pmw.paylogic.com/frontoffice/954db0d3e5c5932a35a82795bcaa02ef13f71887/"
    },
    "profile": {
      "href": "https://shopping-api-docs.paylogic.com/documentation/order.html",
      "type": "text/html"
    },
    "self": {
      "href": "https://shopping-api.paylogic.com/orders/a39f2820687b4c2c9a436920aad150de"
    }
  },
  "consumer": {
    "address": "De Ruijterkade 113",
    "city": "Amsterdam",
    "country": "NL",
    "date_of_birth": "1985-03-08",
    "email": "johndoe@[[EVENTNAME]].com",
    "first_name": "John",
    "gender": 1,
    "ip": "[[IPADDRESS]]",
    "language": "en",
    "last_name": "Doe",
    "phone_number": "088-7000100",
    "postal_code": "1011 AB",
    "state": ""
  },
  "created_date": "[[TIMESTAMPNOW:2017-09-25T15:32:31Z]]",
  "discount": {
    "amount": "0.00",
    "currency": "EUR"
  },
  "grand_total": {
    "amount": "28.10",
    "currency": "EUR"
  },
  "payment_method": {
    "costs": {
      "amount": "1.85",
      "currency": "EUR"
    },
    "image": "https://d3o1gek0fkek2k.cloudfront.net/static/img/payment-icons/paypal.png?v=31a284c8841c8959",
    "name": {
      "en": "PayPal",
    },
    "payment_plan": null,
    "uid": "679d7760808540199687b47bcbbc4b9c"
  },
  "product_selection": [
    {
      "product": "https://shopping-api.paylogic.com/products/90daa1013b134a9caf4d6b42874a678f",
      "quantity": 1,
      "total": {
        "amount": "25.00",
        "currency": "EUR"
      },
      "unit_price": {
        "amount": "25.00",
        "currency": "EUR"
      }
    }
  ],
  "product_total": {
    "amount": "25.00",
    "currency": "EUR"
  },
  "redirect_url": null,
  "reference": "56955664",
  "refund_protection": null,
  "service_costs": {
    "amount": "1.25",
    "currency": "EUR"
  },
  "shipping_method": {
    "costs": {
      "amount": "0.00",
      "currency": "EUR"
    },
    "name": {
      "en": "E-Tickets"
    },
    "uid": "bbc1d3a23a4a431885951a5a22cfc190"
  },
  "status": "pending",
  "uid": "a39f2820687b4c2c9a436920aad150de"
}
`
export default sampleOutput
