<template>
  <div class="cf w-30">
    <span class="fl mr2"></span>
    <span class="fl mr2"></span>
    <span class="fl"></span>
  </div>
</template>

<script>
  export default {
    name: "TrafficLights",
    data () {
      return {
      }
    },
    mounted () {
    },
    methods: {
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  span {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
  }

  span:first-child { background-color: #F55F58; }
  span:nth-child(2) { background-color: #F9BD2E; }
  span:last-child { background-color: #3FC93F; }
</style>
