import "intersection-observer" // Polyfill
import lozad from "lozad"
import {createApp, ref, onMounted, computed} from 'vue';
import { createPinia } from 'pinia'
import h from "./helpers"
import ScrollTrigger from "scrolltrigger-classes"
import Configurator from "./components/Configurator.vue"
import { Modal } from "./components/Modal"
import Parallax from "./parallax"
import SectionScroll from "./sectionscroll"
import OffCanvasMenu from "./components/OffCanvasMenu"
import JumpLink from "./components/JumpLink"
import LanguageLink from "./components/LanguageLink"
import Carousel from "./components/Carousel"
import GuestEntryForm from "./components/GuestEntryForm"
import TrafficLights from "./components/TrafficLights.vue"
import "../stylesheets/style.scss"
import { state } from "./state";
import { plugin as VueInputAutowidth } from 'vue-input-autowidth'
import * as Sentry from "@sentry/vue";

const pinia = createPinia()
/* eslint-disable no-new */
const app = createApp({
  setup() {
    const sharedState = state()

    return { sharedState: sharedState}
  },
  data() {
    return {
      privateState: {},
    }
  },
  components: {
    configurator: Configurator,
    trafficlights: TrafficLights,
  },
  delimiters: ["${", "}"],
  mounted () {},
  methods: {
    shuffle: () => h.handleShuffle(),
  },
})

const isBuildMode = import.meta.env.MODE === 'production';
if (isBuildMode) {
  Sentry.init({
    app,
    dsn: 'https://bdd173ad960b46d283259e09f96ffba0@sentry.yournext.agency/86',
  });
}

app.use(pinia)
app.use(VueInputAutowidth)
app.mount('#app');

const observer = lozad("[data-lazyload]", {
  rootMargin: "10px 0px", // syntax similar to that of CSS Margin
  threshold: 0.1, // ratio of element convergence
})
observer.observe()

document.addEventListener("DOMContentLoaded", () => {
  new ScrollTrigger(
    {
      addHeight: false,
      once: true,
    },
    document.body,
    window,
  )
})

GuestEntryForm({
  selector: ".js-guestentry-form",
})

Parallax({
  selector: ".rellax",
})

Modal()

SectionScroll({
  el: ".m-object",
})

OffCanvasMenu({
  menu: ".js-offcanvas-menu",
  panel: ".js-offcanvas-panel",
  openers: ".js-offcanvas-open",
  closers: ".js-offcanvas-close",
})

JumpLink({
  selector: "[data-jump]",
})

LanguageLink({
  selector: "[data-language-link]",
})

Carousel({
  selector: ".js-carousel",
})
