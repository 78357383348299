<template>
  <div id="interfaceDesktop" class="pv5-l transition-400">
    <div class="relative ba bw1 bw2-ns br-0 br4 br5-l br--left shadow-7 transition-400 bg-white-10 overflow-hidden" :class="['b--' + this.sharedState.textColor + '-10']">
      <div class="flex items-center pa3 pa4-l transition-400" :class="[this.sharedState.platformTypeId == 1 ? 'bg-fb-blue' : 'bg-' + this.sharedState.textColor + '-10']">
        <trafficlights />

        <div class="f6 w-40 tc code bg-white-10 pa1 br2 truncate transition-400" :class="[this.sharedState.platformTypeId == 1 ? 'white-40' : this.sharedState.textColor + '-40']">
          <span>
            https://{{ this.sharedState.platformTypeId == 1 ? 'fb' : this.sharedState.eventNameUrlFriendly }}.com/{{ this.sharedState.platformTypeId == 1 ? this.sharedState.eventNameUrlFriendly : this.sharedState.productTypeUrlFriendly }}
          </span>
        </div>
      </div>

      <div class="transition-400" :class="[layout != 4 ? 'pa4' : null, this.sharedState.platformTypeId == 1 ? 'bg-white' : null]">
        <div v-if="layout != 4" class="mb4 flex items-center justify-between transition-bg-400" :class="[`b--${this.sharedState.textColor}-10`, this.sharedState.platformTypeId != 1 ? 'pb4 bb' : null]">
          <div v-if="layout != 4">
            <div class="fl">
              <h1 class="f3 f2-l lh-title fw9 dib lh-solid pa3 transition-400 roboto-slab" :class="[`bg-${this.sharedState.linkColor}`, this.sharedState.buttonTextColor]">{{ this.sharedState.eventName }}</h1>
            </div>

            <div class="fl ml3 mt2-l flex justify-center items-center" v-if="this.sharedState.productTypeId == 1 || this.sharedState.productTypeId == 2">
              <span class="tc f3" :class="[this.sharedState.platformTypeId == 1 ? 'black-50' : this.sharedState.textColor + '-50']">2 x</span>

              <span :class="[this.sharedState.platformTypeId == 1 ? 'black-50' : this.sharedState.textColor + '-50']">
                <svg class="v-mid" style="fill:currentColor" width="64" height="64">
                  <path d="M41.397 50.887l-3.033 3.033c-.39.39-1.024.39-1.414 0L10.08 27.05c-.39-.39-.39-1.023 0-1.414l3.033-3.033c2.653 1.436 6.038 1.033 8.28-1.21 2.243-2.242 2.646-5.627 1.21-8.28l3.033-3.033c.39-.39 1.024-.39 1.414 0l26.87 26.87c.39.39.39 1.023 0 1.414l-3.033 3.033c-2.653-1.436-6.038-1.033-8.28 1.21-2.243 2.242-2.646 5.627-1.21 8.28zM19.272 32c-.39.39-.39 1.024 0 1.414l1.414 1.414c.39.39 1.024.39 1.415 0l1.415-1.414c.39-.39.39-1.023 0-1.414L22.1 30.586c-.39-.39-1.023-.39-1.414 0L19.272 32zm5.657-5.657c-.392.39-.392 1.024 0 1.414l1.413 1.415c.39.39 1.024.39 1.414 0l1.415-1.415c.39-.39.39-1.023 0-1.414l-1.415-1.414c-.39-.392-1.023-.392-1.414 0l-1.414 1.413zm5.656-5.657c-.39.39-.39 1.024 0 1.415L32 23.516c.39.39 1.024.39 1.414 0l1.414-1.414c.39-.39.39-1.023 0-1.414l-1.414-1.414c-.39-.39-1.023-.39-1.414 0l-1.414 1.414zm-2.12 17.678c-.392.39-.392 1.024 0 1.414l1.413 1.414c.39.39 1.022.39 1.413 0l9.9-9.9c.39-.39.39-1.023 0-1.413l-1.415-1.416c-.39-.39-1.023-.39-1.414 0l-9.9 9.9z" />
                </svg>
              </span>
            </div>
          </div>

          <div v-if="layout != 4 && this.sharedState.platformTypeId != 1">
            <ul class="dn list flex-l items-center-l justify-center-l">
              <li class="dib fw9 mr4 f4" :class="[this.sharedState.platformTypeId == 1 ? 'black-50' : this.sharedState.textColor + '-50']">Home</li>
              <li class="dib fw9 mr4 f4" :class="[this.sharedState.platformTypeId == 1 ? 'black' : this.sharedState.textColor]">
                <span class="bb bw2" style="padding-bottom:2px" :class="`b--${this.sharedState.linkColor}`">Shop</span>
              </li>
              <li class="dib fw9 mr4 f4" :class="[this.sharedState.platformTypeId == 1 ? 'black-50' : this.sharedState.textColor + '-50']">About Us</li>
              <li class="dib fw9 f4" :class="[this.sharedState.platformTypeId == 1 ? 'black-50' : this.sharedState.textColor + '-50']">Contact</li>
            </ul>
          </div>
        </div>

        <div>
          <transition @enter="layoutEnter" :css="false">
            <layout-travel v-if="layout === 1"></layout-travel>
            <layout-merchandise v-else-if="layout === 2"></layout-merchandise>
            <layout-packages v-else-if="layout === 3"></layout-packages>
            <layout-api v-else-if="layout === 4"></layout-api>
            <layout-tickets v-else></layout-tickets>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { state } from "../../state";
import LayoutTickets from "./LayoutTickets.vue"
import LayoutApi from "./LayoutApi.vue"
import LayoutMerchandise from "./LayoutMerchandise.vue"
import LayoutPackages from "./LayoutPackages.vue"
import LayoutTravel from "./LayoutTravel.vue"
import TrafficLights from "../TrafficLights.vue"
import iconTicket from "../../../assets/icon-ticket-medium.svg"

export default {
  name: "DesktopInterface",
  setup() {
    const sharedState = state()

    return {
      sharedState: sharedState,
    }
  },
  data () {
    return {
      privateState: {
        iconTicket,
      },
    }
  },
  components: {
    "layout-tickets": LayoutTickets,
    "layout-api": LayoutApi,
    "layout-merchandise": LayoutMerchandise,
    "layout-packages": LayoutPackages,
    "layout-travel": LayoutTravel,
    "trafficlights": TrafficLights,
  },
  props: ["layout"],
  mounted () {
  },
  methods: {
    layoutEnter: (el) => handleLayoutEnter(el),
  },
}

const handleLayoutEnter = (el) => {
  anime({
    targets: el,
    opacity: [0, 1],
    scale: [0.8, 1],
    elasticity: 50,
    duration: function () {
      return anime.random(800, 1000)
    },
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
