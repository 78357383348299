import anime from "animejs"

const modalSelector = ".js-modal"
const openerSelector = "[data-modal]"
const closerSelector = ".js-modal-close"
const dialogSelector = ".js-dialog"
const dialogChildSelector = ".js-dialog-child"

const Modal = options => {
  const modals = document.querySelectorAll(modalSelector)
  const dialogs = document.querySelectorAll(dialogSelector)
  const openers = document.querySelectorAll(openerSelector)
  const closers = document.querySelectorAll(closerSelector)

  // Open shit
  for (let i = 0, len = openers.length; i < len; i++) {
    const el = openers[i]
    const modalId = el.getAttribute("data-modal")
    openers[i].addEventListener("click", () => showModal(modalId))
  }

  // Close shit
  for (let i = 0, len = closers.length; i < len; i++) {
    const el = closers[i]
    el.addEventListener("click", () => hideModal(modalSelector, dialogSelector))
  }

  // Close shit through backdrop
  for (let i = 0, len = modals.length; i < len; i++) {
    const el = modals[i]
    el.addEventListener("click", () => hideModal(modalSelector, dialogSelector))
  }

  // Prevent close by click on dialog
  for (let i = 0, len = dialogs.length; i < len; i++) {
    dialogs[i].addEventListener("click", e => e.stopPropagation())
  }

  // Close shit through ESC key
  document.addEventListener("keyup", e => {
    e.keyCode === 27 ? hideModal(modalSelector, dialogSelector) : null
  })
}

const showModal = id => {
  const modal = document.getElementById(id)
  const dialog = modal.querySelector(dialogSelector)
  const dialogChildren = modal.querySelectorAll(dialogChildSelector)

  modal.classList.remove("dn")
  modal.classList.add("active")
  modal.classList.add("flex-l")

  anime({
    targets: modal,
    opacity: [0, 1],
    easing: "easeOutCubic",
    duration: 200,
  })

  anime({
    targets: dialog,
    opacity: [0, 1],
    scale: [1.1, 1],
    easing: "easeOutCubic",
    duration: 200,
  })

  anime({
    targets: dialogChildren,
    opacity: [0, 1],
    translateY: [100, 0],
    duration: 250,
    easing: "easeOutCubic",
    delay: function (el, i, l) {
      return 100 + i * 40
    },
  })
}

const hideModal = modalSelector => {
  // Check if there's an active modal
  const modal = document.querySelector(`${modalSelector}.active`)

  // Exit if there ain't
  if (!modal) return false

  // Fetch dialog the little bish
  const dialog = modal.querySelector(dialogSelector)

  anime({
    targets: dialog,
    opacity: 0,
    scale: 0.9,
    easing: "easeOutCubic",
    duration: 200,
  })

  anime({
    targets: modal,
    opacity: 0,
    easing: "easeInCubic",
    duration: 200,
    complete: () => {
      modal.classList.remove("active")
      modal.classList.remove("flex-l")
      modal.classList.add("dn")
    },
  })
}

export { Modal, showModal, hideModal }
