<template>
  <div>
    <div v-for="(item, i) in privateState.lineItems" :key="i" class="w-100 cf" v-bind:class="[i === 0 ? null : 'mt4']">
      <div class="fl">
        <div class="db br3 pa2 overflow-hidden" v-bind:class="[this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']">
          <img class="v-mid" alt="Ticket icon" :src="privateState.icon">
        </div>
      </div>

      <div class="fl w-60 w-50-ns pl3 pl4-l mb3 mb0-l">
        <div class="h1 transition-400 mb3" v-bind:class="[`w-${item.firstRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-20' : 'bg-' + this.sharedState.textColor + '-20']"></div>
        <div class="h1 transition-400 mb3" v-bind:class="[`w-${item.secondRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']"></div>
        <div class="h1 transition-400" v-bind:class="[`w-${item.thirdRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']"></div>
      </div>

      <div class="fr-l w-30-l">
        <div class="fl w-50 pr2 pr3-l">
          <incrementer />
        </div>

        <div class="fr w-50 pl2 pl3-l">
          <button @click="showModal('featuredcasesmodal')" class="db dim w-100 ttu fw9 f4 h-3 br3 transition-400 pointer bw0 outline-0" :class="['bg-' + this.sharedState.linkColor, this.sharedState.buttonTextColor]">Buy</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Incrementer from "../Incrementer.vue"
import { showModal } from "../Modal"
import icon from "../../../assets/icon-ticket-medium.svg"
import { state } from "../../state";

export default {
  name: "LayoutTickets",
  setup() {
    const sharedState = state()

    return { sharedState }
  },
  data () {
    return {
      privateState: {
        icon,
        lineItems: [
          { firstRow: 75, secondRow: 25, thirdRow: 50 },
          { firstRow: 60, secondRow: 40, thirdRow: 40 },
          { firstRow: 80, secondRow: 20, thirdRow: 60 },
          { firstRow: 75, secondRow: 40, thirdRow: 50 },
        ],
      },
    }
  },
  components: {
    "incrementer": Incrementer,
  },
  methods: {
    showModal,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
