import anime from "animejs"
import axios from "axios"
import h from "../../helpers"
import { hideModal } from "../Modal"

const GuestEntryForm = (options) => {
  const forms = document.querySelectorAll(options.selector)

  if (!forms) return false

  for (let i = 0, len = forms.length; i < len; i++) {
    const el = forms[i]
    el.addEventListener("submit", handleSubmit)
  }
}

const handleSuccess = (btn) => {
  btn.style.backgroundColor = "#3FC93F"
  const btnLabelSuccess = btn.querySelector("span.label-success")
  btnLabelSuccess.classList.remove("dn")

  anime({
    targets: btnLabelSuccess,
    opacity: [0, 1],
    scale: [1.2, 1],
    elasticity: 50,
    duration: 400,
  })

  setTimeout(() => {
    hideModal(".js-modal")
  }, 2000)
}

const handleError = (btn) => {
  btn.style.backgroundColor = "#F55F58"
  const btnLabelError = btn.querySelector("span.label-error")
  btnLabelError.classList.remove("dn")

  anime({
    targets: btnLabelError,
    opacity: [0, 1],
    scale: [1.2, 1],
    elasticity: 50,
    duration: 400,
  })
}

const handleSubmit = function (e) {
  e.preventDefault()
  const formEl = this
  const btn = formEl.querySelector("button")
  const btnLabels = btn.querySelectorAll("span")
  const data = h.serialize(formEl)

  anime({
    targets: btnLabels,
    opacity: 0,
    scale: 0.2,
    elasticity: 50,
    duration: 400,
    complete: () => {
      for (var i = 0; i < btnLabels.length; i++) {
        var el = btnLabels[i]
        el.classList.add("dn")
      }
    },
  })

  // Post request
  const settings = {
    url: formEl.getAttribute("action"),
    data: data,
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      "X-Requested-With": "XMLHttpRequest",
    },
  }

  axios(settings)
    .then(function (response) {
      console.log(response)
      response.data.success ? handleSuccess(btn) : handleError(btn)
    })
    .catch(function (error) {
      console.log(error)
    })
}

export default GuestEntryForm
