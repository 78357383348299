import Flickity from "flickity"
import "flickity-imagesloaded"
import "./flickity.scss"
import "./style.scss"

const Carousel = options => {
  const els = document.querySelectorAll(options.selector)
  if (!els) return false

  for (let i = 0, len = els.length; i < len; i++) {
    const el = els[i]

    /* eslint-disable no-new */
    new Flickity(el, {
      cellAlign: "left",
      contain: true,
      arrowShape: {
        x0: 15,
        x1: 65,
        y1: 50,
        x2: 70,
        y2: 45,
        x3: 25,
      },
      imagesLoaded: true,
      pageDots: false,
    })
  }
}

export default Carousel
