import Rellax from "rellax"

const Parallax = options => {
  const els = document.querySelectorAll(options.selector)
  if (!els.length) return false

  /* eslint-disable no-new */
  new Rellax(options.selector, {
    center: true,
    round: true,
  })
}

export default Parallax
