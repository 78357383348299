<template>
  <div class="cf nl3 nr3">
    <div v-for="(item, i) in privateState.lineItems" :key="i" class="w-50-l fl-l ph3" v-bind:class="[(i === 0 || i === 1) ? null : 'mt4-l', i === 0 ? null : 'dn db-l']">
      <div class="flex-l flex-row-l cf">
        <div class="w-50-l br3 mb3 mb0-l flex-l justify-center-l items-center-l overflow-hidden" v-bind:class="[this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']">
          <div class="tc pa2">
            <img class="v-mid" alt="Ticket icon" :src="privateState.icon">
          </div>
        </div>

        <div class="w-50-l fl-l pl4-l">
          <div class="mb3 flex flex-column overflow-hidden">
            <ul class="list ma0 pa0 cf w5">
              <li class="fl mr2" v-for="i in item.stars" :key="i" :class="this.sharedState.linkColor">
                <svg class="v-mid" width="24" height="24" style="fill:currentColor">
                  <path d="M12 19l-7.053 3.708 1.347-7.854L.587 9.292l7.886-1.146L12 1l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854" />
                </svg>
              </li>
            </ul>
          </div>

          <div class="mb3 w-100">
            <div class="h1 transition-400 mb3" v-bind:class="[`w-${item.firstRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-20' : 'bg-' + this.sharedState.textColor + '-20']"></div>
            <div class="h1 transition-400 mb3" v-bind:class="[`w-${item.secondRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']"></div>
          </div>

          <div class="w-100">
            <div class="mb3 w-100">
              <peoplepicker />
            </div>

            <div class="w-100">
              <button @click="showModal('featuredcasesmodal')" class="db dim w-100 ttu fw9 f4 h-3 br3 transition-400 pointer bw0 outline-0" :class="['bg-' + this.sharedState.linkColor, this.sharedState.buttonTextColor]">Buy</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PeoplePicker from "../PeoplePicker.vue"
import { showModal } from "../Modal"
import icon from "../../../assets/icon-hotel-large.svg"
import {state} from "../../state";

export default {
  name: "LayoutTravel",
  setup() {
    const sharedState = state()

    return { sharedState }
  },
  data () {
    return {
      privateState: {
        icon,
        lineItems: [
          { stars: 5, firstRow: 75, secondRow: 25 },
          { stars: 4, firstRow: 60, secondRow: 40 },
          { stars: 3, firstRow: 80, secondRow: 20 },
          { stars: 4, firstRow: 75, secondRow: 40 },
        ],
      },
    }
  },
  components: {
    "peoplepicker": PeoplePicker,
  },
  methods: {
    showModal,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
