<template>
  <div class="h-3 cf white br3 overflow-hidden transition-400" v-bind:class="[this.sharedState.platformTypeId == 1 ? 'bg-black-20' : 'bg-' + this.sharedState.textColor + '-20']">
    <button class="fl white bw0 outline-0 pointer bg-transparent w-third h-3 transition-400" v-on:click="decrement">
      <svg class="v-mid" width="24" height="24" style="fill:currentcolor">
        <path d="M2 11h20v2H2z" />
      </svg>
    </button>

    <div class="fl flex items-center justify-center w-third h-3 transition-400 fw9 tc f3">
      {{ privateState.qty }}
    </div>

    <button class="fr white bw0 outline-0 pointer bg-transparent w-third h-3 transition-400" v-on:click="increment">
      <svg class="v-mid" width="24" height="24" style="fill:currentcolor">
        <path d="M13 11V2h-2v9H2v2h9v9h2v-9h9v-2h-9z" />
      </svg>
    </button>
  </div>
</template>

<script>
import {state} from "../state";

export default {
  name: "Incrementer",
  setup() {
    const sharedState = state()

    return { sharedState }
  },
  data () {
    return {
      privateState: {
        qty: 0,
      },
    }
  },
  mounted () {
  },
  methods: {
    increment () {
      return this.privateState.qty < 9 ? this.privateState.qty++ : null
    },
    decrement () {
      return this.privateState.qty > 0 ? this.privateState.qty-- : null
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
