<template>
  <div class="h6 overflow-hidden br-0 br4--bottom-left br5--bottom-left-l">
    <div class="rellax" data-rellax-speed="-9">
      <pre class="ma0 language-json" v-html="privateState.json"></pre>
    </div>
  </div>
</template>

<script>
import Prism from "prismjs"
import "prismjs/components/prism-json"
import Parallax from "../../parallax"
import sampleOutput from "./sample-json"
const json = Prism.highlight(sampleOutput, Prism.languages.json)

export default {
  name: "LayoutApi",
  data () {
    return {
      privateState: {
        json,
      },
    }
  },
  mounted () {
    Parallax({
      selector: ".rellax",
    })
  },
  components: {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
