import scrollMonitor from "scrollmonitor"
import anime from "animejs"

const SectionScroll = function (options) {
  const observables = Array.from(document.querySelectorAll(options.el))
  if (!observables.length) return false
  const controls = Array.from(document.querySelectorAll("#featurePageDots li"))

  observables.forEach((observable, i) => {
    const sectionWatcher = scrollMonitor.create(observable)
    const entry = observable.querySelector(".entry")
    const index = entry.getAttribute("data-index")

    sectionWatcher.stateChange(function () {
      if (this.isAboveViewport) {
        if (this.isInViewport) {
          entry.style.position = "fixed"
          entry.style.top = 0

          controls.forEach((control, i) => {
            control.className = "moon-gray"
          })
          controls[index].className = "black"

          anime({
            targets: entry,
            opacity: 1,
            translateY: -20,
            duration: 400,
            easing: "easeOutCubic"
          })
        }
      } else {
        anime({
          targets: entry,
          opacity: 0,
          translateY: 20,
          duration: 400,
          easing: "easeOutCubic",
          complete: () => {
            // entry.style.position = "relative"
            // entry.style.top = "auto"
          }
        })
      }
    })
  })

  const container = document.querySelector("#featuresContainer")
  const containerWatcher = scrollMonitor.create(container)

  containerWatcher.stateChange(function () {
    if (!this.isAboveViewport) {
      const controls = Array.from(
        document.querySelectorAll("#featurePageDots li")
      )
      controls.forEach((control, i) => {
        control.className = "moon-gray"
      })
    }

    if (!this.isBelowViewport) {
      anime({
        targets: container,
        opacity: 0,
        duration: 400,
        easing: "easeOutCubic"
      })
    } else {
      anime({
        targets: container,
        opacity: 1,
        duration: 400,
        easing: "easeOutCubic"
      })
    }
  })
}

export default SectionScroll
