import Cookies from "js-cookie"

const handleClick = locale => {
  Cookies.set("locale", String(locale), { expires: 365 })
}

const LanguageLink = options => {
  const els = document.querySelectorAll(options.selector)

  for (let i = 0, len = els.length; i < len; i++) {
    const el = els[i]
    const locale = el.getAttribute("data-language-link")
    el.addEventListener("click", () => handleClick(locale))
  }
}

export default LanguageLink
