import { state } from "./state"

const helpers = {
  handleShuffle () {
    const data = state().bucket
    // Get random event type
    // (Remove last item because it's the complicated one)
    const eventTypes = data.eventTypes.slice(0, -1)
    // Get random event type
    const randEventType = Math.floor(Math.random() * eventTypes.length)
    state().setEventType(
      eventTypes[randEventType].text,
      eventTypes[randEventType].bgColor,
      eventTypes[randEventType].bgImage,
      eventTypes[randEventType].textColor,
      eventTypes[randEventType].linkColor,
      eventTypes[randEventType].buttonTextColor,
      eventTypes[randEventType].logo
    )
    // Get random event name
    const eventNames = data.eventNames
    const randEventName = Math.floor(Math.random() * eventNames.length)
    state().setEventName(eventNames[randEventName])
    state().setDefaultEventName(eventNames[randEventName])
    // Get random product type
    const productTypes = data.productTypes
    const randProductType = Math.floor(Math.random() * productTypes.length)
    state().setProductType(productTypes[randProductType], randProductType)
    // Get random platform type
    // (Remove last item because it's the API one)
    const platformTypes = data.platformTypes.slice(0, -1)
    const randPlatformType = Math.floor(Math.random() * platformTypes.length)
    state().setPlatformType(platformTypes[randPlatformType], randPlatformType)
  },

  getRandomInt (min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min // The maximum is exclusive and the minimum is inclusive
  },

  preventDefault (e) {
    e = e || window.event
    if (e.preventDefault) {
      e.preventDefault()
    }
    e.returnValue = false
  },

  debounce (fn, delay) {
    let timer = null
    return function () {
      let context = this
      let args = arguments
      clearTimeout(timer)
      timer = setTimeout(function () {
        fn.apply(context, args)
      }, delay)
    }
  },

  serialize (form) {
    if (!form || form.nodeName !== "FORM") {
      return
    }

    let i = []
    let j = []
    let q = []

    for (i = form.elements.length - 1; i >= 0; i = i - 1) {
      if (form.elements[i].name === "") {
        continue
      }

      switch (form.elements[i].nodeName) {
        case "INPUT":
          switch (form.elements[i].type) {
            case "text":
            case "number":
            case "email":
            case "tel":
            case "hidden":
            case "password":
            case "button":
            case "reset":
            case "submit":
              q.push(
                form.elements[i].name +
                  "=" +
                  encodeURIComponent(form.elements[i].value)
              )
              break
            case "checkbox":
            case "radio":
              if (form.elements[i].checked) {
                q.push(
                  form.elements[i].name +
                    "=" +
                    encodeURIComponent(form.elements[i].value)
                )
              }
              break
            case "file":
              break
          }
          break

        case "TEXTAREA":
          q.push(
            form.elements[i].name +
              "=" +
              encodeURIComponent(form.elements[i].value)
          )
          break

        case "SELECT":
          switch (form.elements[i].type) {
            case "select-one":
              q.push(
                form.elements[i].name +
                  "=" +
                  encodeURIComponent(form.elements[i].value)
              )
              break

            case "select-multiple":
              for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                if (form.elements[i].options[j].selected) {
                  q.push(
                    form.elements[i].name +
                      "=" +
                      encodeURIComponent(form.elements[i].options[j].value)
                  )
                }
              }
              break
          }
          break

        case "BUTTON":
          switch (form.elements[i].type) {
            case "reset":
            case "submit":
            case "button":
              q.push(
                form.elements[i].name +
                  "=" +
                  encodeURIComponent(form.elements[i].value)
              )
              break
          }
          break
      }
    }
    return q.join("&")
  }
}

export default helpers
