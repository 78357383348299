import jump from "jump.js"

const JumpLink = options => {
  const els = document.querySelectorAll(options.selector)

  for (let i = 0, len = els.length; i < len; i++) {
    const el = els[i]
    const target = el.getAttribute("data-jump")
    el.addEventListener("click", e => {
      e.preventDefault()
      handleClick(target)
    })
  }
}

const handleClick = target => {
  jump(target, {
    duration: 200,
  })
}

export default JumpLink
