<template>
  <div id="interfaceMobile" class="pv3 pv5-l transition-400" v-bind:class="[this.sharedState.platformTypeId === 0 ? 'o-40 pointer-events-none' : null]">
    <div class="relative bt bl br bw1 bw2-ns br4 br5-l shadow-7 transition-400 bg-white-10 overflow-hidden" v-bind:class="['b--' + this.sharedState.textColor + '-10']">
      <div class="pa3 pa4-l transition-400" v-bind:class="[this.sharedState.platformTypeId == 1 ? 'bg-fb-blue' : 'bg-' + this.sharedState.textColor + '-10']">
        <div class="f6 tc code w-100 truncate transition-400" v-bind:class="[this.sharedState.platformTypeId == 1 ? 'white-40' : this.sharedState.textColor + '-40']">
          <span>
            https://{{ this.sharedState.platformTypeId == 1 ? 'fb' : this.sharedState.eventNameUrlFriendly }}.com/{{ this.sharedState.platformTypeId == 1 ? this.sharedState.eventNameUrlFriendly : this.sharedState.productTypeUrlFriendly }}
          </span>
        </div>
      </div>

      <div class="pa4 transition-400" v-bind:class="[this.sharedState.platformTypeId == 1 ? 'bg-white' : null]">
        <h1 class="f3 lh-title fw9 mb4 bg-black dib lh-solid pa3 transition-400 roboto-slab" v-bind:class="['bg-' + this.sharedState.linkColor, this.sharedState.buttonTextColor]">{{ this.sharedState.eventName }}</h1>

        <div v-for="(item, i) in privateState.lineItems" :key="i" class="w-100 cf" v-bind:class="[i === 0 ? null : 'mt4']">
          <div class="mb4">
            <div class="fl">
              <div class="db br3 pa2 overflow-hidden" v-bind:class="[this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']">
                <img class="v-mid" alt="Product icon" v-if="this.sharedState.productTypeId == 1" :src="privateState.icons[0]">
                <img class="v-mid" alt="Product icon" v-else-if="this.sharedState.productTypeId == 2" :src="privateState.icons[1]">
                <img class="v-mid" alt="Product icon" v-else-if="this.sharedState.productTypeId == 0" :src="privateState.icons[2]">
                <img class="v-mid" alt="Product icon" v-else :src="privateState.icons[Math.floor(Math.random() * privateState.icons.length)]">
              </div>
            </div>

            <div class="fl w-50 pl3">
              <div class="h1 transition-400 mb3" v-bind:class="[`w-${item.firstRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-20' : 'bg-' + this.sharedState.textColor + '-20']"></div>
              <div class="h1 transition-400 mb3" v-bind:class="[`w-${item.secondRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']"></div>
              <div class="h1 transition-400 mb3" v-bind:class="[`w-${item.thirdRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']"></div>
            </div>
          </div>

          <div class="w-100 cf">
            <div class="fl w-50 pr2">
              <incrementer />
            </div>

            <div class="fr w-50 pl2">
              <button @click="showModal('featuredcasesmodal')" class="db dim w-100 ttu fw9 f4 h-3 br3 transition-400 pointer bw0 outline-0" :class="['bg-' + this.sharedState.linkColor, this.sharedState.buttonTextColor]">Buy</button>
            </div>
          </div>
        </div>
      </div>

      <div class="pa3 transition-400" :class="['bg-' + this.sharedState.textColor + '-10']">
        <div class="w-100 transition-400">
          <span v-if="homeBtn === 'round'" class="ba bw2 w3 h3 br5 db center" :class="['b--' + this.sharedState.textColor + '-10']"></span>
          <span v-if="homeBtn === 'roundRect'" class="ba bw2 w4 h2 br5 db center" :class="['b--' + this.sharedState.textColor + '-10']"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Incrementer from "./Incrementer.vue"
import { showModal } from "./Modal"
import iconHotel from "../../assets/icon-hotel-medium.svg"
import iconShirt from "../../assets/icon-shirt-medium.svg"
import iconTicket from "../../assets/icon-ticket-medium.svg"
import { state } from "../state";

export default {
  name: "MobileInterface",
  setup() {
    const sharedState = state()

    return { sharedState }
  },
  data () {
    return {
      privateState: {
        icons: [iconHotel, iconShirt, iconTicket],
        lineItems: [
          { firstRow: 75, secondRow: 25, thirdRow: 50 },
          { firstRow: 60, secondRow: 40, thirdRow: 70 },
        ],
      },
    }
  },
  props: {
    homeBtn: {
      type: String,
      default: "round",
    },
  },
  components: {
    "incrementer": Incrementer,
  },
  mounted () {
  },
  methods: {
    showModal,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
