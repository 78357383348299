import anime from "animejs"

let menu = null
let panel = null
let openers = null
let closers = null
let isActive = false

const OffCanvasMenu = options => {
  menu = document.querySelector(options.menu)
  panel = document.querySelector(options.panel)
  openers = document.querySelectorAll(options.openers)
  closers = document.querySelectorAll(options.closers)
  // Prevent execution if elements aren't present
  if (!menu || !panel) return false
  // Open shit
  for (let i = 0, len = openers.length; i < len; i++) {
    openers[i].addEventListener("click", () => {
      !isActive ? handleOpen() : handleClose()
    })
  }
  // Close shit
  for (let i = 0, len = closers.length; i < len; i++) {
    closers[i].addEventListener("click", handleClose)
  }
  // Close shit through panel
  panel.addEventListener("click", handleClose)
  // Close shit through ESC key
  document.addEventListener("keyup", e => {
    e.keyCode === 27 ? handleClose() : null
  })
}

const showMenuItems = () => {
  const items = menu.querySelectorAll("li")

  anime({
    targets: items,
    opacity: [0, 1],
    translateY: [20, 0],
    duration: 400,
    easing: "easeOutCubic",
    delay: function (el, i, l) {
      return i * 40
    },
  })
}

const showMenu = () => {
  const menuWidth = menu.offsetWidth

  anime({
    targets: [panel, ".is-moved-by-drawer"],
    easing: "easeInOutCubic",
    translateX: menuWidth * -1,
    duration: 250,
  })

  showMenuItems()
}

const hideMenu = () => {
  anime({
    targets: [panel, ".is-moved-by-drawer"],
    easing: "easeOutCubic",
    translateX: 0,
    duration: 200,
  })
}

const handleOpen = () => {
  if (isActive) return false
  showMenu()
  // Add mini timeout to prevent handleClose from firing
  setTimeout(() => {
    isActive = true
  }, 1)
}

const handleClose = () => {
  if (!isActive) return false
  hideMenu()
  isActive = false
}

export default OffCanvasMenu
