<template>
  <div class="cf nl3 nr3">
    <div v-for="item in privateState.lineItems" :key="item.id" class="w-third fl ph3">
      <div class="w-100">
        <div class="mb4 tc br3 pa2 w-100 overflow-hidden" :class="[this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']">
          <img class="v-mid" alt="Ticket icon" :src="privateState.icons[0]">
          <img class="v-mid" alt="Ticket icon" :src="privateState.icons[1]">
          <img class="v-mid" alt="Ticket icon" :src="privateState.icons[2]">
        </div>
      </div>

      <div class="mb4 w-100">
        <div class="h1 transition-400 mb3" :class="[`w-${item.firstRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-20' : 'bg-' + this.sharedState.textColor + '-20']"></div>
        <div class="h1 transition-400 mb3" :class="[`w-${item.secondRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']"></div>
        <div class="h1 transition-400" :class="[`w-${item.thirdRow}`, this.sharedState.platformTypeId == 1 ? 'bg-black-10' : 'bg-' + this.sharedState.textColor + '-10']"></div>
      </div>

      <div class="w-100">
        <div class="mb3 w-100">
          <incrementer />
        </div>

        <div class="w-100">
          <button @click="showModal('featuredcasesmodal')" class="db dim w-100 ttu fw9 f4 h-3 br3 transition-400 pointer bw0 outline-0" :class="['bg-' + this.sharedState.linkColor, this.sharedState.buttonTextColor]">Buy</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Incrementer from "../Incrementer.vue"
import { showModal } from "../Modal"
import iconHotel from "../../../assets/icon-hotel-large.svg"
import iconShirt from "../../../assets/icon-shirt-large.svg"
import iconTicket from "../../../assets/icon-ticket-large.svg"
import {state} from "../../state";

export default {
  name: "LayoutPackages",
  setup() {
    const sharedState = state()

    return { sharedState }
  },
  data () {
    return {
      privateState: {
        icons: [
          iconHotel,
          iconTicket,
          iconShirt,
        ],
        lineItems: [
          { id: 0, firstRow: 75, secondRow: 25, thirdRow: 50 },
          { id: 1, firstRow: 60, secondRow: 40, thirdRow: 40 },
          { id: 2, firstRow: 80, secondRow: 20, thirdRow: 60 },
        ],
      },
    }
  },
  components: {
    "incrementer": Incrementer,
  },
  methods: {
    showModal,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
