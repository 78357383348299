<template>
  <div v-if="this.sharedState.bucket">
    <Bio />

    <div class="relative z-0 tc mb4 mb0-l nt2 nt3-l">
      <button @click="shuffle" class="f6 nowrap ba br2 ph3 pv2 sans-serif outline-0 ttu tracked-light fw9 transition-400 bg-transparent pointer" :class="[sharedState.linkColor, `b--${sharedState.linkColor}`]">{{ shuffleBtnLabel }}</button>
    </div>

    <div class="cf overflow-hidden">
      <div class="relative ph2 ph4-l cf flex-ns items-end-ns">
        <div class="w-100 w-50-ns w-third-l w-25-xl ph2 ph4-l fl-ns" v-bind:class="[sharedState.platformTypeId != 2 ? 'dn' : null]">
          <MobileInterface homeBtn="round" />
        </div>

        <transition v-on:enter="interfaceEnter" v-bind:css="false">
          <div class="w-50-ns w-third-l w-25-xl ph2 ph4-l dn db-ns fl-ns" v-if="sharedState.platformTypeId == 2">
            <MobileInterface homeBtn="false" />
          </div>
        </transition>

        <transition v-on:enter="interfaceEnter" v-bind:css="false">
          <div class="w-third-l w-25-xl ph4 dn db-l fl-l" v-if="sharedState.platformTypeId == 2">
            <MobileInterface homeBtn="roundRect" />
          </div>
        </transition>

        <transition v-on:enter="interfaceEnter" v-bind:css="false">
          <div class="w-25-xl ph4 dn db-xl fl-xl" v-if="sharedState.platformTypeId == 2">
            <MobileInterface homeBtn="round" />
          </div>
        </transition>

        <transition v-on:enter="interfaceEnter" v-bind:css="false">
          <div class="w-100 w-50-ns w-75-l fl-l" v-if="sharedState.platformTypeId != 2">
            <div class="pl2 pl4-l nr2 nr4-l">
              <DesktopInterface v-if="sharedState.platformTypeId == 3" :layout="4"></DesktopInterface>
              <DesktopInterface v-else :layout="sharedState.productTypeId"></DesktopInterface>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { HTTP } from "../http-common"
import h from "../helpers"
import Bio from "./Bio.vue"
import MobileInterface from "./MobileInterface.vue"
import DesktopInterface from "./DesktopInterface/DesktopInterface.vue"
import { ref, onMounted, computed } from 'vue'
import { state } from '../state'

export default {
  name: "Configurator",
  setup() {
    const sharedState = state()

    return { sharedState }
  },
  data () {
    return {
      privateState: {},
    }
  },
  components: {
    Bio,
    MobileInterface,
    DesktopInterface,
  },
  props: {
    shuffleBtnLabel: {
      type: String,
      required: true,
    },
  },
  mounted () {
    // Get data out of Craft
    HTTP
      .get("homepage.json")
      .then(response => {
        const data = response.data.data[0]
        // Put the whole shebang into the shared state
        this.sharedState.setBucket(data)
      })
      // Set initial state for event types
      .then(() => this.shuffle())
      // Unhide element
      .then(() => {
        const appEl = this.$root.$el.parentElement
        appEl.classList.remove("lazyload")
        appEl.classList.add("lazyloaded")
      })
      .then(() => {
        const els = this.$el.parentElement.querySelectorAll(".js-spring-entrance")
        handleAttention(els);
        document.getElementById("eventNameInput").focus()
        setTimeout(() => this.sharedState.toggleEventTypeSelect(), 1000)
      })
  },
  methods: {
    // Define methods that trigger actions defined in store
    shuffle: () => h.handleShuffle(),
    interfaceEnter: (el) => handleInterfaceEnter(el),
  },
  computed: {
  },
}

const handleInterfaceEnter = (el) => {
  anime({
    targets: el,
    opacity: [0, 1],
    scale: [0.1, 1],
    elasticity: 200,
    duration: function () {
      return anime.random(800, 1200)
    },
  })
}

const handleAttention = (items) => {
  anime({
    targets: items,
    scale: [1, 1.2, 1],
    duration: 600,
    easing: "easeOutCubic",
    delay: function (el, i, l) {
      return i * 50
    },
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
