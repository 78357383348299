<template>
  <div class="nl2 nr2 cf">
    <div v-for="(size, i) in privateState.people" :key="i" class="h-3 overflow-hidden fl w-third ph2">
      <button class="white bw0 outline-0 pointer h-3 br3 db w-100" v-on:click="setPeople(i)" :class="[
        this.sharedState.platformTypeId == 1 ? 'bg-black-20' : 'bg-' + this.sharedState.textColor + '-20',
        i === privateState.size ? 'o-50' : null
      ]">
        <div class="flex items-center justify-center h-3 fw9 tc f3">
          <img class="v-mid" alt="People" :src="privateState.people[i]">
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import iconAlpha from "../../assets/icon-people-alpha.svg"
import iconBeta from "../../assets/icon-people-beta.svg"
import iconGamma from "../../assets/icon-people-gamma.svg"
import {state} from "../state";

export default {
  name: "SizePicker",
  setup() {
    const sharedState = state()

    return { sharedState }
  },
  data () {
    return {
      privateState: {
        people: [
          iconAlpha,
          iconBeta,
          iconGamma,
        ],
        size: 0,
      },
    }
  },
  mounted () {
  },
  methods: {
    setPeople: function (index) {
      this.privateState.size = index
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
