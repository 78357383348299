<template>
  <div v-if="this.sharedState.bucket" class="relative mw9 center ph3 ph5-l ph0-xl pt4 pb4 pb5-l pv5-xl tc">
    <p class="f2 f-4-l f-subheadline-xl lh-title fw9" :class="[this.sharedState.bgImage ? 'ts-1' : null]">
      <span v-if="this.sharedState.bucket.staticTextAlpha">{{ this.sharedState.bucket.staticTextAlpha }}</span>
      <span :class="privateState.selectClasses">
        <span :class="privateState.springClasses">
          <button @click="this.sharedState.toggleEventTypeSelect()"
                  :class="[this.sharedState.eventTypeSelect ? 'pointer-events-none' : null, privateState.buttonClasses, this.sharedState.linkColor]">
            {{ this.sharedState.eventType }}
          </button>
        </span>

        <transition @enter="selectEnter" :css="false">
          <span :class="privateState.containerClasses" v-if="this.sharedState.eventTypeSelect">
            <span :class="privateState.modalClasses">
              <em v-for="(eventType, i) in this.sharedState.bucket.eventTypes" :key="i"
                  :class="[privateState.optionClasses, eventType.text == this.sharedState.eventType ? ' bg-' + this.sharedState.linkColor + ' white' : 'hover-bg-' + this.sharedState.linkColor]"
                  @click="this.sharedState.setEventType(eventType.text, eventType.bgColor, eventType.bgImage, eventType.textColor, eventType.linkColor, eventType.buttonTextColor, eventType.logo)">{{
                  eventType.text
                }}</em>
            </span>
          </span>
        </transition>
      </span>

      <span v-if="this.sharedState.bucket.staticTextBeta">{{ this.sharedState.bucket.staticTextBeta }}</span>

      <span :class="privateState.springClasses">
        <input id="eventNameInput" ref="eventNameInput" size="" @keydown="handleKeyDown" type="text" v-model="eventName"
               :placeholder="this.sharedState.defaultEventName"
               :class="[privateState.inputClasses, this.sharedState.linkColor]">
      </span>

      <span v-if="this.sharedState.bucket.staticTextGamma">{{ this.sharedState.bucket.staticTextGamma }}</span>

      <span :class="privateState.selectClasses">
        <span :class="privateState.springClasses">
          <button @click="this.sharedState.toggleProductTypeSelect()"
                  :class="[this.sharedState.productTypeSelect ? 'pointer-events-none' : null, privateState.buttonClasses, this.sharedState.linkColor]">
            {{ this.sharedState.productType }}
          </button>
        </span>

        <transition @enter="selectEnter" :css="false">
          <span :class="privateState.containerClasses" v-if="this.sharedState.productTypeSelect">
            <span :class="privateState.modalClasses">
              <em v-for="(productType, i) in this.sharedState.bucket.productTypes" :key="i"
                  :class="[privateState.optionClasses, productType == this.sharedState.productType ? ' bg-' + this.sharedState.linkColor + ' white' : 'hover-bg-' + this.sharedState.linkColor]"
                  @click="this.sharedState.setProductType(productType, i)">{{ productType }}</em>
            </span>
          </span>
        </transition>
      </span>

      <span v-if="this.sharedState.bucket.staticTextDelta">{{ this.sharedState.bucket.staticTextDelta }}</span>

      <span :class="privateState.selectClasses">
        <span :class="privateState.springClasses">
          <button @click="this.sharedState.togglePlatformTypeSelect()"
                  :class="[this.sharedState.platformTypeSelect ? 'pointer-events-none' : null, privateState.buttonClasses, this.sharedState.linkColor]">
            {{ this.sharedState.platformType }}
          </button>
        </span>

        <transition @enter="selectEnter" :css="false">
          <span :class="privateState.containerClasses" v-if="this.sharedState.platformTypeSelect">
            <span :class="privateState.modalClasses">
              <em v-for="(platformType, i) in this.sharedState.bucket.platformTypes" :key="i"
                  :class="[privateState.optionClasses, platformType == this.sharedState.platformType ? ' bg-' + this.sharedState.linkColor + ' white' : 'hover-bg-' + this.sharedState.linkColor]"
                  @click="this.sharedState.setPlatformType(platformType, i)">{{ platformType }}</em>
            </span>
          </span>
        </transition>
      </span>

      <span v-if="this.sharedState.bucket.staticTextEpsilon">{{ this.sharedState.bucket.staticTextEpsilon }}</span>

      <span :class="privateState.selectClasses">
        <span :class="privateState.springClasses">
          <button @click="this.sharedState.toggleColorSchemeSelect()"
                  :class="[this.sharedState.colorSchemeSelect ? 'pointer-events-none' : null, privateState.buttonClasses, this.sharedState.linkColor]">
            {{ this.sharedState.bucket.colorSchemesButtonLabel }}
          </button>
        </span>

        <transition @enter="swatchSelectEnter" :css="false">
          <span :class="privateState.containerClasses" v-if="this.sharedState.colorSchemeSelect">
            <span :class="privateState.modalClasses">
    <div class="bb b--near-white pb3 pb4-l">
      <em :class="privateState.titleClasses">
        {{ this.sharedState.bucket.colorSchemesBackgroundColorHeading }}
      </em>

      <div class="ph2 ph3-l">
        <em v-for="(bgColor, i) in this.sharedState.bucket.bgColors" :key="i" :class="[privateState.swatchClasses, `bg-${bgColor}`]"
            @click="this.sharedState.setBgColor(bgColor)"></em>
      </div>
    </div>

    <div class="pt3 pt4-l">
      <em :class="privateState.titleClasses">
        {{ this.sharedState.bucket.colorSchemesLinkColorHeading }}
      </em>

      <div class="ph2 ph3-l">
        <em v-for="(linkColor, i) in this.sharedState.bucket.linkColors" :key="i" :class="[privateState.swatchClasses, `bg-${linkColor}`]"
            @click="this.sharedState.setLinkColor(linkColor)"></em>
      </div>
    </div>
    </span>
    </span>
    </transition>
    </span>

    <span v-if="this.sharedState.bucket.staticTextZeta">{{ this.sharedState.bucket.staticTextZeta }}</span>
    </p>
    <div
        ref="mirror" id="mirror" :class="this.privateState.inputClasses"></div>
  </div>
</template>

<script>
import anime from "animejs"
import {state} from "../state";

export default {
  name: "Bio",
  setup() {
    const sharedState = state()

    return {
      sharedState: sharedState,
    }
  },
  data() {
    return {
      privateState: {
        selectClasses: "relative fw9 lh-title pointer pink",
        springClasses: "dib js-spring-entrance",
        buttonClasses: "transition-400 lh-title sans-serif relative fw9 pointer bw0 outline-0 bg-transparent",
        containerClasses: "db absolute z-1 left-0 top-100 w-100 tc",
        modalClasses: "dib f3 f2-l bg-white br4 br5-l shadow-6 pv3 pv4-l tc arrow-up white overflow-hidden",
        optionClasses: "db ph4 pv2 black hover-white fw9 fs-normal ts-none",
        titleClasses: "db mb3 ph4 pv2 black nowrap fw9 fs-normal ts-none",
        swatchClasses: "dib pointer mh2 mh3-l w2 w2-5-l h2 h2-5-l br5",
        inputClasses: "transition-400 bw0 ma0 pa0 lh-title bio-input-adjust outline-0 bg-transparent sans-serif fw9 overflow-hidden mw-100"
      },
    }
  },
  components: {},
  mounted() {
    const mirror = this.$refs.mirror;
    mirror.setAttribute("aria-hidden", "true");
    document.body.appendChild(mirror);
    this.updateStyles();
  },
  watch: {
    eventName: {
      handler(val) {
        this.inputResize()
      },
    },
  },
  methods: {
    selectEnter: (el) => handleSelectEnter(el),
    swatchSelectEnter: (el) => handleSwatchSelectEnter(el),
    handleKeyDown: function (event) {
      if (event.keyCode !== 8 && this.$refs.eventNameInput.value.length > 20) {
        event.preventDefault()
      }
    },
    inputResize() {
      this.updateStyles();
      const mirror = this.$refs.mirror;
      const input = this.$refs.eventNameInput;
      let value = input.value || input.placeholder;
      if (!value) {
        return;
      }

      while (mirror.childNodes.length) {
        mirror.removeChild(mirror.childNodes[0]);
      }

      mirror.appendChild(document.createTextNode(value));
      input.style.width = mirror.scrollWidth + 2 + 'px';
    },
    updateStyles() {
      const mirror = this.$refs.mirror;
      const styles = window.getComputedStyle(this.$refs.eventNameInput);
      mirror.style.fontSize = styles.fontSize;
      mirror.style.fontFamily = styles.fontFamily;
      mirror.style.fontWeight = styles.fontWeight;
      mirror.style.fontStyle = styles.fontStyle;
      mirror.style.letterSpacing = styles.letterSpacing;
      mirror.style.textTransform = styles.textTransform;
    }
  },
  computed: {
    eventName: {
      get: function () {
        return this.sharedState.eventName
      },
      set: function (newName) {
        return this.sharedState.eventName = newName
      }
    }
  },
  updated() {
    this.inputResize()
  },
}

const handleSelectEnter = (el) => {
  const items = el.querySelectorAll("em")

  anime({
    targets: el,
    opacity: [0, 1],
    scale: [0.1, 1],
    elasticity: 200,
    duration: 400
  })

  anime({
    targets: items,
    opacity: [0, 1],
    translateY: [20, 0],
    elasticity: 200,
    duration: 600,
    delay: function (el, i, l) {
      return 60 + (i * 60)
    }
  })
}

const handleSwatchSelectEnter = (el) => {
  const items = el.querySelectorAll("em")

  anime({
    targets: el,
    opacity: [0, 1],
    scale: [0.1, 1],
    elasticity: 200,
    duration: 400
  })

  anime({
    targets: items,
    opacity: [0, 1],
    translateY: [20, 0],
    elasticity: 200,
    duration: 600,
    delay: function (el, i, l) {
      return 60 + (i * 20)
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#mirror {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  white-space: pre;
}

input {
  height: calc(2.25rem * 1.25);

  @media screen and (min-width: 60em) {
    height: calc(3.75rem * 1.25);
  }

  @media screen and (min-width: 90em) {
    height: calc(4.5rem * 1.25);
  }
}
</style>
