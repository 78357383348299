// store_new.js
import { defineStore } from 'pinia'

export const state = defineStore('state', {
  state: () => {
    return {
      bucket: null,

      logo: null,
      bgColor: null,
      bgImage: null,
      textColor: null,
      linkColor: null,
      buttonTextColor: null,

      eventName: null,
      defaultEventName: null,
      eventType: null,
      eventNameUrlFriendly: null,
      eventTypeSelect: false,

      productType: null,
      productTypeId: null,
      productTypeUrlFriendly: null,
      productTypeSelect: false,

      platformType: null,
      platformTypeId: null,
      platformTypeUrlFriendly: null,
      platformTypeSelect: false,

      colorSchemeSelect: false,
      debug: false,
    };
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    testAction (value) {
      this.test = '321;'
    },
    setBucket (object) {
      if (this.debug) console.log("setBucket triggered with", object)
      this.bucket = object
    },
    setEventType (
      type,
      bgColor,
      bgImage,
      textColor,
      linkColor,
      buttonTextColor,
      logo,
    ) {
      if (this.debug) {
        console.log(
          "setEventType triggered with",
          type,
          bgColor,
          bgImage,
          textColor,
          linkColor,
          logo,
        )
      }
      this.eventType = type
      this.bgColor = bgColor
      this.bgImage = bgImage
      this.textColor = textColor
      this.linkColor = linkColor
      this.buttonTextColor = buttonTextColor
      this.logo = logo
    },
    toggleEventTypeSelect () {
      if (this.debug) console.log("toggleEventTypeSelect triggered")
      this.eventTypeSelect = !this.eventTypeSelect
    },
    setDefaultEventName (newValue) {
      if (this.debug) console.log("setEventName triggered with", newValue)
      this.defaultEventName = newValue
    },
    setEventName (newValue) {
      if (this.debug) console.log("setEventName triggered with", newValue)
      this.eventName = newValue
      this.eventNameUrlFriendly = newValue
        .replace(/\s+/g, "-")
        .toLowerCase()
    },
    setBgColor (newValue) {
      if (this.debug) console.log("setBgColor triggered with", newValue)
      this.bgColor = newValue
    },
    setLinkColor (newValue) {
      if (this.debug) console.log("setLinkColor triggered with", newValue)
      this.linkColor = newValue
    },
    hideAllSelects () {
      if (this.debug) console.log("hideAllSelects triggered")
      this.eventTypeSelect = false
      this.productTypeSelect = false
      this.platformTypeSelect = false
      this.colorSchemeSelect = false
    },
    setProductType (newValue, id) {
      if (this.debug) {
        console.log("setProductType triggered with", newValue, id)
      }
      this.productType = newValue
      this.productTypeId = id
      this.productTypeUrlFriendly = newValue
        .replace(/\s+/g, "-")
        .toLowerCase()
    },
    toggleProductTypeSelect () {
      if (this.debug) console.log("toggleProductTypeSelect triggered")
      this.productTypeSelect = !this.productTypeSelect
    },
    setPlatformType (newValue, id) {
      if (this.debug) {
        console.log("setPlatformType triggered with", newValue, id)
      }
      this.platformType = newValue
      this.platformTypeId = id
      this.platformTypeUrlFriendly = newValue
        .replace(/\s+/g, "-")
        .toLowerCase()
    },
    togglePlatformTypeSelect () {
      if (this.debug) console.log("togglePlatformTypeSelect triggered")
      this.platformTypeSelect = !this.platformTypeSelect
    },
    toggleColorSchemeSelect () {
      if (this.debug) console.log("toggleColorSchemeSelect triggered")
      this.colorSchemeSelect = !this.colorSchemeSelect
    },
  },
})
